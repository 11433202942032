
import firemage from "../../Assets/firemage.gif";
import "./AboutMe.css";


const AboutMe = () => {
  return (
    <div className="construction">
      <h1>Under Construction</h1>
      <img src={firemage} />
    </div>
  )
}

export default AboutMe; 